.custom-offcanvas.offcanvas.offcanvas-start {
  top: 95px;
  left: 0;
  width: 15%; /* Default width for larger screens */
  max-width: 15%; /* Ensure it doesn't exceed 90% of screen width */
  background-color: #272838; /* Background color */
  color: #ffffffc7; /* Text color */
  border: 1px solid #ccc; /* Border color and width */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for depth */

  /* Responsive adjustments for small screens */
  @media (max-width: 768px) {
    width: 15%; /* Full width on small screens */
    max-width: none; /* Remove max-width for small screens */
    top: 0; /* Adjust top positioning */
    padding: 1rem; /* Add padding for small screens */
    box-shadow: none; /* Optionally remove box-shadow on small screens */
  }
}

.manual-details-accordion {
  justify-content: center;

  .accordion-header {
    background-color: #272838;
    color: white;
    padding: 15px;
    cursor: pointer;
  }

  .accordion-body {
    padding: 15px;
    border: 5px solid #302f2f; /* Example border */
  }
}

.custom-offcanvas input[type="checkbox"] {
  accent-color: blue; /* Set this to your desired color */
}

.custom-offcanvas input[type="checkbox"] {
  accent-color: initial; /* Reset to default accent color */
  color: initial; /* Reset to default color */
  background-color: initial; /* Reset to default background color */
  border-color: initial; /* Reset to default border color */
  /* Or set a specific color you prefer */
  accent-color: blue; /* Set this to your desired color */
}

.tool-labels {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Adjust spacing between labels as needed */
}

.matched-tool-label {
  background-color: hwb(118 16% 33%) !important;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px; /* Adjust border radius as needed */
  font-size: 0.9em; /* Adjust font size as needed */
  margin: 2px 0;
}

.additional-tool-label {
  background-color: hwb(239 27% 15%) !important;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px; /* Adjust border radius as needed */
  font-size: 0.9em; /* Adjust font size as needed */
  margin: 2px 0;
}

.notfound-tool-label {
  background-color: #ff562b !important;
  color: #fff;
  padding: 5px 10px;
  border-radius: 15px; /* Adjust border radius as needed */
  font-size: 0.9em; /* Adjust font size as needed */
  margin: 2px 0;
}

.accordion-header {
  user-select: text; /* Allow text selection */
}

.manual-details-listgroup {
  .manual-item {
    border: 4px solid #272838; /* Border around each manual */
    border-radius: 0.375rem; /* Rounded corners */
    padding: 1rem; /* Padding inside each item */
    margin-bottom: 1rem; /* Space between items */
    background-color: #ffffff; /* Background color of the item */

    .tool-labels {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }

    .tool-label {
      background-color: #f8f9fa; /* Badge background color */
      border-radius: 0.2rem; /* Rounded corners for badges */
    }
  }
}

.info-container {
  display: flex; /* Use flexbox layout */
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.manual-name,
.manual-id {
  margin: 0;
  padding: 0;
}

.container {
  margin-top: 20px;
}

.center-end-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center-content {
  flex: 1; /* This allows the element to take up available space */
  display: flex;
  justify-content: center;
}

a {
  display: inline; /* Ensure the anchor tag does not behave like a block element */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // Ensure the overlay is above all other elements
}

.loading-overlay .spinner-border {
  width: 3rem;
  height: 3rem;
  color: white; // Spinner color
}

.toggle-container {
  border: 2px solid #000;
  padding: 10px;
  padding-left: 50px;
  border-radius: 8px;
  background-color: #272838;
  color: #f8f9fa;
}

.scrollable-list {
  max-height: 100px; /* Set the height as per your requirement */
  overflow-y: auto;
}

.fixed-height {
  min-height: 100vh;
  position: relative;
}

.feedback-session {
  background-color: transparent;
  padding: 0 0 8px 0;
  border: unset;
  justify-content: end;
  display: flex;
  flex-flow: row;
  gap: 8px;
}

.navbar-custom {
  a {
    color: black;
  }
}

.pagination-wrapper {
  gap: 8px;
}

.custom-pagination {
  .page-item {
    .page-link {
      color: black;
    }
    &.active {
      .page-link {
        color: white;
        background-color: #4c4b63;
        border-color: #4c4b63;
      }
    }
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  margin-top: 20vh;
  img {
    width: 250px;
  }
}
