.border-dashed {
  border-style: dashed;
}

/* Add this to your CSS file or component */
.dropdown-menu-wide {
  min-width: 150px; /* Adjust the width as per your requirement */
}

.custom-dropdown-menu {
  max-height: 200px; /* Adjust height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the icon */
  left: 50%;
  margin-left: -100px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.upload-pdf-card {
  background-color: rgb(239, 239, 245);
  width: 30%;
}

.upload-form-item {
  gap: 4px;
}

.upload-form {
  gap: 12px;
}

.upload-form-title-wapper {
  gap: 4px;
}

.info-icon {
  margin-bottom: 14px;
}

.manual-finder-container {
  padding: 16px;
  gap: 16px;
}

.uploaded-file-names {
  max-height: 200px;
  overflow-y: auto;
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.filter-icon-wrapper {
  transition: margin 0.3s ease, transform 0.3s ease;

  &.shift-left {
    margin-right: 445px;
  }
}
