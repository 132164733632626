.filter-block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.result-header {
  padding: 12px 24px 8px 24px;
}

.filter-container {
  padding: 0 !important;
  margin: 0 !important;
}

.result-header {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
