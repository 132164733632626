$manual-drawer-width: 445px;

.w-80 {
  width: 80%;
}

.scrollable-list {
  max-height: 700px; /* Set the height as per your requirement */
  overflow-y: auto;
}

.card-color {
  background-color: #272838; /* Replace with your desired color */
}

.custom-checkbox .form-check-input {
  border: 2px solid #272838; /* Change 'red' to your desired color */
}

.manual-modal-footer {
  display: flex;
  justify-content: space-between;
}

.customer-feedback-buttons {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.image-count {
  font-size: 14px;
}

.image-card {
  background-color: #272838;
  color: #f8f9fa;
}

.drop-file-button {
  width: 0.6rem;
  height: auto;
}

.extracted-images-action-card {
  width: 20%;
}

.manual-drawer {
  width: $manual-drawer-width !important;
}

.extracted-images-container {
  gap: 24px;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 20px;

  transition: margin 0.3s ease, transform 0.3s ease;

  &.shift-left {
    margin-right: $manual-drawer-width;
  }
}

.action-card {
  background-color: rgb(239, 239, 245);
  .scrollable-list {
    max-height: 55vh;
    overflow-y: auto;
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;

  img {
    width: 250px;
  }
}
