// Override Navbar

.navbar {
  background-color: white;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: var(--header-z-index);

  .navbar-brand,
  .navbar-brand:hover {
    color: var(--primary-text-color);
  }

  .navbar-brand__title {
    font-weight: bold;
    font-size: 1.25rem;

    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }

    @media screen and (max-width: 475px) {
      display: none;
    }
  }

  .navbar-brand__logo {
    height: 32px;

    @media screen and (max-width: 768px) {
      height: 25px;
    }
  }

  .navbar-nav .nav-link {
    color: var(--primary-text-color);

    span {
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  .container {
    padding: unset;
  }
}

.nav-bar {
  .sub-nav {
    a > img {
      width: 80px;
      height: 80px;
    }
  }
}

.navbar {
  position: unset !important;
}
